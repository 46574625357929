module.exports.switchUrl = 'http://localhost:4102/dev/api/v1/switch'
module.exports.earthUrl = 'http://localhost:4002/dev/api/v1'
module.exports.checkoutUrl = 'http://localhost:4007/dev/api/v1'
module.exports.paystackUrl = 'http://localhost:4009/dev/api/v1'
module.exports.dnaUrl = 'http://localhost:5402/dev/api/v1'
module.exports.paymentEnvironemnt = 'test'
module.exports.antarUrl = 'http://localhost:4202/dev/api/v1'
module.exports.adyenClientKey = 'test_MAQ2WWBW35CI7AE5PJBHY744SAHKNSXJ'
module.exports.adyenPaymentEnvironemnt = 'test'
module.exports.gPayEnv = 'TEST'
module.exports.gPayPublishKey = 'pk_sbox_qpravplcrp3k75nth65diubfiim'
module.exports.gPayMerchantId = 'BCR2DN6TQ7YNFCDT'
module.exports.gPayMerchantName = 'Foodhub'
module.exports.gPaySupportedCardNetworks = 'VISA,MASTERCARD,AMEX,DISCOVER'
module.exports.aPayMerchantIdentifier = 'merchant.com.foodhub.sandbox'
module.exports.aPayDomainName = 'nd-2155-apple-pay-web.dw62m7utw2a83.amplifyapp.com'
module.exports.aPayMerchantDisplay = 'Foodhub'
module.exports.aPayVersionNumber = '14'
module.exports.aPaySupportedCardNetworks = "visa,masterCard,amex,discover,maestro"
module.exports.marsUrl = 'http://localhost:4005/dev/api/v1'
module.exports.spreedlyEnvironmentKey = 'Upwmj2nJXCacteS0QdaY1FoTa6r'
module.exports.restApiUrl = 'http://localhost:4008/dev/api/v1'
module.exports.saturnBaseUrl = 'https://ws6-payments.datmanpay.com/saturn'
module.exports.aPayAdyenMerchantIdentifier = 'merchant.com.adyen.foodhub.test'
module.exports.gPayAdyenMerchantAccount = 'FoodHubLimited'
module.exports.egTimeoutRetryCount = '3'
module.exports.egTimeoutRetryInterval = '10000'
